<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="用户昵称" prop="nickname">
                <a-input v-model="queryParam.nickname" placeholder="请输入用户昵称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="真实姓名" prop="userName">
                <a-input v-model="queryParam.userName" placeholder="请输入真实姓名" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="用户手机号" prop="mobile">
                <a-input v-model="queryParam.mobile" placeholder="请输入用户手机号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="身份证号" prop="idCard">
                <a-input v-model="queryParam.idCard" placeholder="请输入身份证号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :auditResultsOptions="dict.type.attest_audit_results"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >

        <span slot="idCardFront" slot-scope="text, record">
            <ImageViewer class="imageViewer"   v-if="record.idCardFront"  ref="previewImg"
                 v-for="(guildLogo,imgIndex) in record.idCardFront.split(',')"
                 :imageUrl="guildLogo"
                 preview="图片" />
        </span>

        <span slot="idCardBack" slot-scope="text, record">
               <ImageViewer class="imageViewer"   v-if="record.idCardBack"  ref="previewImg"
                            v-for="(guildLogo,imgIndex) in record.idCardBack.split(',')"
                            :imageUrl="guildLogo"
                            preview="图片" />
        </span>

        <span slot="auditResults" slot-scope="text, record">
          <dict-tag :options="dict.type['attest_audit_results']" :value="record.auditResults"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a v-if="record.auditResults == 0" @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['biz:user:edit']">
            审核
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageUser,listUser, delUser } from '@/api/biz/userAttest'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import ImageViewer from '@/components/Viewer/ImageViewer.vue'

export default {
  name: 'User',
  components: {
    CreateForm,ImageViewer
  },
  mixins: [tableMixin],
  dicts: ['attest_audit_results'],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        userOpenId: null,
        userName: null,
        avatar: null,
        userMobile: null,
        picture: null,
        serviceQualification: null,
        idCard: null,
        userBankMobile: null,
        openingBank: null,
        bankCardNumber: null,
        idCardFront: null,
        idCardBack: null,
        holdingIdPhoto: null,
        gameSkills: null,
        certifiedImage: null,
        voicePresent: null,
        auditResults: null,
        reason: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '序号',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function (t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '用户昵称',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户手机号',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '真实姓名',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '身份证号',
          dataIndex: 'idCard',
          ellipsis: true,
          align: 'center',width:"160px"
        },
        {
          title: '身份证正面',
          dataIndex: 'idCardFront',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: "idCardFront"}
        },
        {
          title: '身份证反面',
          dataIndex: 'idCardBack',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: "idCardBack"}
        },
        {
          title: '审核结果',
          dataIndex: 'auditResults',
          scopedSlots: { customRender: 'auditResults' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '拒绝原因',
          dataIndex: 'reason',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',width:"160px"
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {

    /** 查询用户认证列表 */
    getList () {
      this.loading = true
     pageUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        userOpenId: undefined,
        userName: undefined,
        avatar: undefined,
        userMobile: undefined,
        picture: undefined,
        serviceQualification: undefined,
        idCard: undefined,
        userBankMobile: undefined,
        openingBank: undefined,
        bankCardNumber: undefined,
        idCardFront: undefined,
        idCardBack: undefined,
        holdingIdPhoto: undefined,
        gameSkills: undefined,
        certifiedImage: undefined,
        voicePresent: undefined,
        auditResults: undefined,
        reason: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delUser(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/userAttest/export', {
            ...that.queryParam
          }, `用户认证_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
<style lang="less" scoped>
//图片样式
.imageViewer{
  width: 48px;height:48px;display: flex;justify-content: center;margin: auto;
}
</style>
